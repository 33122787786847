.tasting {
    margin: 1.5rem 2.5rem;
    padding: 0 2.5rem;
    border: 1px solid #1b140d;
    background: #f0f0f0;
    box-shadow: .75rem .75rem 0 0 #1b140d;
    position: relative;

    h2 {
        font-size: 1.25rem;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('assets/boxtexture.png') repeat;
        opacity: .5;
        z-index: 0;
    }

    > * {
        position: relative;
        z-index: 1;
    }

    &.tasting-root {
        transition: .1s box-shadow, .1s margin;

        &:hover {
            box-shadow: 1rem .85rem 0 0 #1b140d;
            margin: 1.5rem 2.7rem 1.5rem 2.3rem;
        }

        a::before {
            content: '';
            position: absolute;
            top: -.83em;
            right: -2.5rem;
            bottom: -.83em;
            left: -2.5rem;
        }
    }

    p {
        cursor: default;
        font-size: 1rem;
        font-weight: 400;
        margin: .25rem 0 0;

        time {
            display: block;
            font-style: italic;
        }
    }
}
